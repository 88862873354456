<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="pv-30 flex flex-ac flex-w">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">用户名：</span>
          <el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">手机号：</span>
          <el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">是否过期：</span>
          <el-select class="w-250" v-model="retrieveForm.isGq" clearable placeholder="请选择">
            <el-option label="未过期" :value="0">
            </el-option>
            <el-option label="已过期" :value="1">
            </el-option>
          </el-select>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">是否使用：</span>
          <el-select class="w-250" v-model="retrieveForm.isUse" clearable placeholder="请选择">
            <el-option label="未使用" :value="0">
            </el-option>
            <el-option label="已使用" :value="1">
            </el-option>
          </el-select>
        </div>
        <div class="mr-30 mt-20 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">创建时间：</span>
          <el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
          </el-date-picker>
        </div>
        <div class="mr-30 mt-20 flex flex-ac">
          <span class="fs-16 flex1 mr-20 w-80">使用时间：</span>
          <el-date-picker class="w-250" v-model="retrieveForm.useDate" type="date" valueFormat="yyyy-MM-dd"
            placeholder="选择创建时间">
          </el-date-picker>
        </div>
        <div class="mt-20">
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="loading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.userImg" alt="" class="smallImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
    <el-dialog title="使用信息" :visible.sync="dialogUseVisible">
      <el-descriptions title="">
        <el-descriptions-item label="系列id">{{perspectiveUseData.seriesId}}</el-descriptions-item>
        <el-descriptions-item label="系列名称">{{perspectiveUseData.seriesName}}</el-descriptions-item>
        <el-descriptions-item label="套数">{{perspectiveUseData.seriesNum}}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{perspectiveUseData.tipDetailsId}}</el-descriptions-item>
        <el-descriptions-item label="商品id">{{perspectiveUseData.tipDetailsName}}</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUseVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        userName: '',
        phone: '',
        isGq: 0,//默认不过期
        isUse: '', //默认未使用
        startDate: '',
        endDate: '',
        useDate: '',
      },
      startEndDate: [], //时间区间
      loading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '头像', prop: 'img', align: 'left', width: '80' },
        { label: '用户名', prop: 'userName', align: 'left', 'show-overflow-tooltip': true },
        { label: '手机号', prop: 'phone', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '结束时间', prop: 'endDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '使用时间', prop: 'updateDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '提示商品', prop: 'tipDetailsName', align: 'left', 'show-overflow-tooltip': true },
        { label: '商品套数', prop: 'seriesNum', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '删除', fn: this.deleteList, },
            {
              type: 'text', text: '使用详情', fn: this.rowDetails, hidden: (e) => {
                return e == 1
              }
            }
          ],
        },
      ],
      tableData: [],
      //使用详情
      dialogUseVisible: false,
      perspectiveUseData: "",
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //时间变化
    handPickerChange (value) {
      if (value && value.length != 0) {
        this.retrieveForm.startDate = value[0]
        this.retrieveForm.endDate = value[1]
      } else {
        this.retrieveForm.startDate = ""
        this.retrieveForm.endDate = ""
      }
    },
    //获取表格数据
    getTableList () {
      this.loading = true
      this.$http.get('/userTip/findUserTipList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          userName: this.retrieveForm.userName,
          phone: this.retrieveForm.phone,
          isGq: this.retrieveForm.isGq,
          isUse: this.retrieveForm.isUse,
          startDate: this.retrieveForm.startDate,
          endDate: this.retrieveForm.endDate,
          useDate: this.retrieveForm.useDate,
        }
      }).then(({ data: result }) => {
        this.loading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/userTip/removeTip', {
          ids: row.id,
        }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) { },
    //使用详情
    rowDetails (row) {
      this.dialogUseVisible = true
      this.perspectiveUseData = row
    },
  },
}
</script>

<style lang="less" scoped>
</style>